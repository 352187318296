@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: flex;
  flex-direction: column;
  // margin-bottom: 20px;
}

.listingCards {
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
  //justify-content: center;
}

.cardBase {
  min-height: 150px;
  width: 250px;
  margin: 20px;
  padding: 10px;
  border-radius: 2px;
  height: fit-content;
  //box-shadow: $primary-box-shadow;

  display: grid;
  grid-auto-rows: auto auto 60px;
  grid-template-areas: 'title' 'desc' 'btn';

  border: 1px solid $primary-gray;

  overflow: hidden;
}

.cardTitle {
  grid-area: title;
  width: 250px;
  overflow: hidden;
  font-weight: 700;
  height: fit-content;
}

.cardDesc {
  grid-area: desc;
  width: 250px;
  overflow: hidden;
  height: 250px;
}

.button {
  grid-area: btn;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.image_cell {
  display: flex;
  align-items: center;
  height: 65px;

  > span {
    margin-left: 10px;
  }
}

.continue_button {
  @include flex-center;
  width: 150px;
  height: 40px;
  background-color: $primary-color;
  color: white;
  margin-top: 20px;
  align-self: flex-end;
  cursor: pointer;
  margin-bottom: 20px;
}

.recommended_coaches {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.coaches {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.listings {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.listing_actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.listing_price {
  font-weight: 600;
  font-size: 20px;
}

.listing_button {
  font-weight: 600;
  color: red;
  cursor: pointer;
}

.coach_card_base {
  width: 450px;
  border: 1px solid $primary-gray;
  border-radius: 2px;
  padding: 20px;
  gap: 10px;
  display: grid;
  grid-template-rows: 50px auto 30px;
}

.coach_name_base {
  display: flex;
  gap: 10px;
}

.coach_name {
  :nth-child(1) {
    font-weight: bold;
  }
  :nth-child(2) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
  :nth-child(3) {
    font-size: 12px;
    color: $primary-gray-darker;
  }
}

.coach_description {
  color: $primary-gray;
  margin-top: 10px;
  overflow: hidden;
}

.coach_actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.coach_button {
  cursor: pointer;
  color: $primary-color;
  font-weight: 600;
}

.coach_title {
  margin: 10px 0;
  font-weight: bold;
  color: $primary-gray;
}

.listings {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.container {
  grid-template-columns: 200px auto 200px;
  gap: 8px;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }
}

.left_container {
  padding: 30px;
  border-right: 3px solid #6b6b6c;
}

.header_bg {
  height: 60px;
}

.listing_card {
  display: grid;
  grid-template-columns: 200px auto 200px;
  gap: 10px;
  border: 1px solid $primary-gray;
  padding: 10px;

  @include tablet {
    grid-template-columns: 1fr;
  }
}

.listing_image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.listing_details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 200px;
}

.listing_categories {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  > div {
    padding: 10px;
    background-color: lighten($primary-color, 70);
    border-radius: 100px;
    font-size: 12px;
  }
}

.listing_description {
  color: $primary-gray;
  overflow: hidden;
}

.listing_description * {
  font-size: 15px !important;
}

.listing_action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :nth-child(1) {
    font-weight: 600;
  }
}

.button3 {
  @include flex-center;
  height: 50px;
  width: 90%;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;
}

.button2 {
  @include flex-center;
  height: 50px;
  width: 90%;
  //background-color: $primary-color;
  border: 1px solid $primary-color;

  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;
  box-sizing: border-box;
  color: black;
}

.coach_name {
  text-decoration: none;
  color: #007373;
  font-weight: 700;
}
