@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
}

.close_button {
  position: absolute;
  top: -18px;
  right: -18px;
}

.title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input_field {
  margin-bottom: 20px !important;
}

.submit_button {
  margin-top: 20px !important;
}