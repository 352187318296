@import '../../../scss/variables';
@import '../../../scss/mixins';

.details {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.title {
  font-weight: 500;
  font-size: 20px;
}

.avatar {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 250px;
}

.avatar_details > p:nth-child(1) {
  font-weight: 500;
  font-size: 18px;
}

.avatar_details > p:nth-child(2) {
  color: $primary-gray-darker;
}

.budget_card {
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: lighten($primary-color, 70%);
  font-weight: 600;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;

  :nth-child(2) {
    color: $primary-color;
  }
}

.button {
  @include flex-center;
  height: 50px;
  width: 180px;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
}

.tabNav {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tags_list {
  display: grid;
  grid-template-columns: repeat(4, auto);
  //flex-wrap: wrap;
  gap: 5px; 
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
}

.tag {
  background-color: #e4feff;
  color: #000000; //017373
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
  transition: background-color 0.3s ease;
  margin-right: 5px;
}

.assign_tag {
  background-color: #017373;
  color: #ffffff; //017373
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
  transition: background-color 0.3s ease;
  margin-right: 5px;
  cursor: pointer;
}

.tag:hover {
  background-color: #3c7172;
  color: white;
}

.remove_tag_btn {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  border-radius: 50%;
  margin-left: 5px !important;
  background-color: #bdbdbd !important;
  color: rgb(255, 255, 255) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove_tag_btn:hover {
  background-color: #9c0b0b !important;
  color: rgb(255, 255, 255) !important;
}

.close_icon {
  font-size: 10px !important; 
}

.smallIcon {
  margin-right: 2px;
  vertical-align: middle !important;
  margin-left: -5px !important;
  width: 12px;
  height: 12px;
}

.notext{
  display: inline !important;
  margin-right: 5px;
}
