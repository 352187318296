@import 'src/scss/mixins';
@import 'src/scss/variables';

.base{
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 5px;
}

.close_button{
  position: absolute;
  top: -18px;
  right: -18px;
}

.title{
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
  margin-top: 10px;
}

.submit_button {
  margin-top: 20px !important;
  width: 100%;
  margin-bottom: 10px !important;
}
