@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
}

.close_button {
  position: absolute;
  top: -18px;
  right: -18px;
}

.title {
  font-size: 20px;
  font-weight: 400;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input_field {
  margin-bottom: 20px !important;
}

.submit_button {
  margin-top: 20px !important;
}

.action_button {
  margin-left: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.text_button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 35px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  border: 2px solid $primary-color;
  background-color: $primary-color;
  padding: 0 10px;  
}