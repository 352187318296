@import '../../../../scss/variables';

.table_custom {
  width: 100%;
  border: 1px solid #d1d5db;
  table-layout: fixed;
  border-collapse: collapse;
}

.table_row {
  background-color: #e5e7eb;
  text-align: left;
}

@media print {
  .table_row {
    background-color: #e5e7eb !important;
  }
}

.table_row_normal {
  text-align: left;
}

.table_cell {
  padding: 8px;
  border: 1px solid #d1d5db;
}

.table_cell_amount {
  padding: 8px;
  border: 1px solid #d1d5db;
  color: #007373;
  font-weight: bold;
}

