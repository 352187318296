@import 'src/scss/mixins';
@import 'src/scss/variables';

.base {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
}

.close_button {
  position: absolute;
  top: -18px;
  right: -18px;
}

.title {
  font-size: 20px;
  font-weight: 400;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input_field {
  margin-bottom: 20px !important;
}

.submit_button {
  margin-top: 20px !important;
}

.action_button {
  margin-left: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.text_button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 35px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  border: 2px solid $primary-color;
  background-color: $primary-color;
  padding: 0 10px;  
}

.tag_list {
  display: flex;
  flex-wrap: wrap;  
  gap: 8px;  
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: flex-start; 
}

.tag {
  display: flex;
  align-items: center;
  flex-wrap: nowrap; 
  background-color: #e4feff;
  color: #000000;
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #074705;
  white-space: nowrap; 
}

.count_circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;  
  height: 20px;
  border-radius: 50%;
  background-color: #007373; 
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
  border: 1px solid #007373;
  margin-right: -5px !important;
}

.mr{
  margin-right: 100px !important;
}